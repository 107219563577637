import {
  FormControl,
  Typography,
  FormControlLabel,
  Switch,
  Box,
  Link,
  SelectChangeEvent,
  Badge,
  IconButton,
} from "@mui/material";
import { Input } from "../../common/input";
import { ChangeEvent, useState } from "react";
import Grid from "@mui/material/Grid2";
import "./style.css";
import Buttons from "../../common/button";
import { SelectInput } from "../../common/selectInput";
type BotSettingsFormProps = {};
const options = [
  {
    text: "Medium",
    value: "Medium",
  },
  {
    text: "Short",
    value: "Short",
  },
  {
    text: "Long",
    value: "Long",
  },
];
const Messaging: React.FC<BotSettingsFormProps> = (props: any) => {
  const [model, setModel] = useState<string>("");
  const { control, setlValue } = props;
  const [conversationalMode, setConversationalMode] = useState<boolean>(false);
  const [postChat, setpostChat] = useState<boolean>(false);
  const [multilingualMode, setMultilingualMode] = useState<boolean>(false);
  const [botResponseLength, setBotResponseLength] = useState<string[]>([
    "Medium",
  ]);

  const [suggestFollowUpQuestions, setSuggestFollowUpQuestions] =
    useState<boolean>(false);
  const [conversationModeValue, setConversationModeValue] =
    useState<boolean>(false);
  const [allowImageUsage, setAllowImageUsage] = useState<boolean>(false);
  const [showSources, setShowSources] = useState<boolean>(true);
  const [welcomeMessage, setWelcomeMessage] = useState<boolean>(false);
  const handleConversationalModeChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setConversationalMode(event.target.checked);
  };
  const handleConversationalMultilingual = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setMultilingualMode(event.target.checked);
  };
  const handleSuggestFollowUpQuestionsChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setSuggestFollowUpQuestions(event.target.checked);
  };
  const handleAllowImageUsageChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setAllowImageUsage(event.target.checked);
  };
  const handleShowSourcesChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowSources(event.target.checked);
  };

  const handleWelcomeMessageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setWelcomeMessage(event.target.checked);
  };
  const handlePostChangeEvent = (event: ChangeEvent<HTMLInputElement>) => {
    setpostChat(event.target.checked);
  };

  return (
    <>
      <Box p={2}>
        <Grid container>
          <Grid size={12}>
            <FormControlLabel
              className="settingFormLable"
              control={
                <Buttons
                  type="secondary"
                  text="gpt-40"
                  sx={{ width: "4.5rem" }}
                />
              }
              label={
                <>
                  <Typography>AI Model</Typography>
                  <Typography variant="caption">
                    Select a model to power your bot
                  </Typography>
                </>
              }
            />
          </Grid>
          <Grid size={12}>
            <FormControl fullWidth margin="normal">
              <FormControlLabel
                className="settingFormLable"
                control={
                  <Switch
                    checked={conversationalMode}
                    onChange={handleConversationalModeChange}
                  />
                }
                label={
                  <>
                    <Typography variant="body1">
                      Conversational Mode
                      <IconButton aria-label="new" sx={{ pl: 3 }}>
                        <Badge badgeContent={"New"} color="primary"></Badge>
                      </IconButton>
                    </Typography>

                    <Typography variant="caption" pr={5}>
                      Segment bot's responses into shorter, more readable
                      messages The entire response still counts as one message
                    </Typography>
                  </>
                }
              />
            </FormControl>
          </Grid>
          <Grid size={12}>
            <FormControl fullWidth margin="normal">
              <FormControlLabel
                className="settingFormLable"
                control={
                  <SelectInput
                    placeholder={"Select AI Model"}
                    fullWidth
                    value={"Medium"}
                    optionValue={options}
                  />
                }
                label={
                  <>
                    <Typography>Response Length</Typography>
                    <Typography variant="caption">
                      Select the response length(s) of your bot
                    </Typography>
                  </>
                }
              />
            </FormControl>
          </Grid>
          <Grid size={12}>
            <FormControl fullWidth margin="normal">
              <FormControlLabel
                className="settingFormLable"
                control={
                  <Switch
                    checked={suggestFollowUpQuestions}
                    onChange={handleSuggestFollowUpQuestionsChange}
                  />
                }
                label={
                  <>
                    <Typography>Suggest Follow Up Questions</Typography>
                    <Typography variant="caption">
                      If enabled, we will suggest 2 new follow up questions from
                      your knowledge base
                    </Typography>
                  </>
                }
              />
            </FormControl>
          </Grid>
          <Grid size={12}>
            <FormControl fullWidth margin="normal">
              <FormControlLabel
                className="settingFormLable"
                control={
                  <Switch
                    checked={allowImageUsage}
                    onChange={handleAllowImageUsageChange}
                  />
                }
                label={
                  <>
                    <Typography>Allow Image Usage</Typography>
                    <Typography variant="caption">
                      Allows the bot to use the scraped images for training and
                      response generation
                    </Typography>
                  </>
                }
              />
            </FormControl>
          </Grid>
          <Grid size={12}>
            <FormControl fullWidth margin="normal">
              <FormControlLabel
                className="settingFormLable"
                control={
                  <Switch
                    checked={showSources}
                    onChange={handleShowSourcesChange}
                  />
                }
                label={
                  <>
                    <Typography>Show Sources with the Response</Typography>
                    <Typography variant="caption">
                      Hide/Show sources along with responses
                    </Typography>
                  </>
                }
              />
            </FormControl>
          </Grid>
          <Grid size={12}>
            <FormControl fullWidth margin="normal">
              <FormControlLabel
                className="settingFormLable"
                control={
                  <Switch checked={postChat} onChange={handlePostChangeEvent} />
                }
                label={
                  <>
                    <Typography>Post chat feedback</Typography>
                    <Typography variant="caption">
                      Hide/Show post chat feedback
                    </Typography>
                  </>
                }
              />
            </FormControl>
          </Grid>
          <Grid size={12}>
            <FormControl fullWidth margin="normal">
              <FormControlLabel
                className="settingFormLable"
                control={
                  <Switch
                    checked={multilingualMode}
                    onChange={handleConversationalMultilingual}
                  />
                }
                label={
                  <>
                    <Typography>Multilingual Support</Typography>
                    <Typography variant="caption">
                      If disabled, the bot will stick to the selected language
                    </Typography>
                  </>
                }
              />
            </FormControl>
          </Grid>
          <Grid size={12}>
            <FormControl fullWidth margin="normal">
              <FormControlLabel
                className="settingFormLable"
                control={
                  <Switch
                    checked={welcomeMessage}
                    onChange={handleWelcomeMessageChange}
                  />
                }
                label={
                  <>
                    <Typography>Show floating welcome message</Typography>
                    <Typography variant="caption">
                      Toggle visibility of the Welcome Message
                    </Typography>
                  </>
                }
              />
            </FormControl>
          </Grid>
          <Grid size={12}>
            <FormControl fullWidth margin="normal">
              <Typography>Welcome Message</Typography>
              <Typography variant="caption">
                Variables can be added using the "@" symbol
                <Link
                  href="#"
                  variant="body2"
                  className="primaryLink"
                  sx={{ ml: "0.5rem" }}
                >
                  Learn more
                </Link>
              </Typography>
              <Input />
            </FormControl>
          </Grid>
          <Grid size={12}>
            <FormControl fullWidth margin="normal">
              <Typography>Message shown when no Source is added</Typography>
              <Input />
            </FormControl>
          </Grid>
          <Grid size={12}>
            <FormControl fullWidth margin="normal">
              <Typography>
                Message shown when there is a Server Error
              </Typography>
              <Input />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Messaging;
