import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import Grid from "@mui/material/Grid2";
import "./style.css";
import {
  Appearance,
  General,
  Messaging,
  StarterQuestion,
  EmailSetup,
  WorkingHours,
  UserForm,
  UsageSecurity,
} from "./form";
import { useEffect, useState } from "react";
import Buttons from "../../common/button";
import { useForm } from "react-hook-form";
import {
  useCreateBotMutation,
  useGetAppearanceMutation,
  useGetOneBotQuery,
  useUpdateAppearanceMutation,
  useUpdateBotMutation,
} from "../../../redux/services/botApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../redux/features/alert/alertSlice";
import { setAssistantName } from "../../../redux/features/assistant/assistantSlice";
import { Loader } from "../../common/loader";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const assistanceTheme = createTheme({
  typography: {
    fontFamily: "Inter",
    fontSize: 14,
    body1: {
      fontSize: "0.875rem",
      color: "var(--bot-color-dark)",
      fontWeight: 600,
    },
    body2: {
      fontSize: "0.75rem",
      color: "var(--bot-color-gray)",
      fontWeight: 400,
      marginBottom: "1.5rem",
    },
    subtitle1: { fontSize: "0.875rem", color: "var(--bot-color-gray)" },
    caption: {
      fontSize: "0.875rem",
      color: "var(--bot-color-gray)",
      fontWeight: 400,
      lineHeight: "0.875rem",
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: "1rem",
          color: "var(--bot-color-dark)",
          fontWeight: 400,
        },
      },
    },
  },
});
export const CreateAssistant = () => {
  const dispatch = useDispatch();
  const updatedBotName = useSelector((state: any) => state?.assistant?.name);
  const chatBotId = useSelector((state: any) => state?.assistant?.id);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedColor, setSelectedColor] = useState();
  const [selectedWidget, setSelectedWidget] = useState();
  const [bot, setBot] = useState<any>({ name: "", description: "" });

  const [updateBot, updateBotInfo] = useUpdateBotMutation<any>();
  const [updateAppearance, updateAppearanceInfo] =
    useUpdateAppearanceMutation<any>();
  const { data, isSuccess, isLoading, refetch, fulfilledTimeStamp } =
    useGetOneBotQuery<any>({
      id: chatBotId,
    });
  const [getAppearance, getAppearanceInfo] = useGetAppearanceMutation<any>();

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      description: "",
    },
  });
  const {
    handleSubmit: submitApperance,
    control: controlAppearance,
    getValues: getValuesAppearance,
    setValue: setValueAppearance,
    reset: appearanceReset,
    formState: { errors: errorsAppearance },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      subheading: "",
    },
  });

  const handleSave = (data: any) => {
    updateBot({ id: chatBotId, ...data });
  };
  const handleSaveAppearance = (data: any) => {
    updateAppearance({
      chatbot_id: chatBotId,
      accent_color: selectedColor,
      widget_icon: selectedWidget,
      ...data,
    });
  };
  useEffect(() => {
    if (updateBotInfo?.isSuccess) {
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: updateBotInfo?.data?.message,
        })
      );
      dispatch(setAssistantName(updateBotInfo?.data?.updated_fields?.name));
      refetch();
    }
  }, [updateBotInfo?.isSuccess]);
  useEffect(() => {
    if (updateAppearanceInfo?.isSuccess) {
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: updateAppearanceInfo?.data?.message,
        })
      );
      getAppearance({ chatbot_id: bot?.id });
    }
  }, [updateAppearanceInfo?.isSuccess]);
  useEffect(() => {
    if (isSuccess) {
      setBot({
        name: data?.name,
        id: data?.chatbot_id,
        description: data?.description,
      });
      reset({
        name: data?.name,
        description: data?.description,
      });
    }
  }, [isSuccess, fulfilledTimeStamp]);
  const handleDiscard = () => {
    switch (selectedIndex) {
      case 0:
        reset({
          name: bot?.name,
          description: bot?.description,
        });
        break;
      case 1:
        appearanceReset();
        setSelectedColor(getAppearanceInfo?.data?.accent_color);
        setSelectedWidget(getAppearanceInfo?.data?.widget_icon);
        break;
      default:
        return reset({
          name: bot?.name,
          description: bot?.description,
        });
    }
  };

  const getForm = () => {
    switch (selectedIndex) {
      case 0:
        return "general";
      case 1:
        return "appearance";
      case 2:
        return "messaging";
      case 3:
        return "starterquestion";
      case 4:
        return "emailsetup";
      default:
        return "general";
    }
  };
  useEffect(() => {
    if (getAppearanceInfo?.isSuccess) {
      appearanceReset({
        name: getAppearanceInfo?.data?.name,
        subheading: getAppearanceInfo?.data?.subheading,
      });
      setSelectedColor(getAppearanceInfo?.data?.accent_color);
      setSelectedWidget(getAppearanceInfo?.data?.widget_icon);
    }
  }, [getAppearanceInfo?.isSuccess, getAppearanceInfo?.fulfilledTimeStamp]);
  return (
    <>
      <Loader
        loader={
          updateBotInfo?.isLoading ||
          updateAppearanceInfo?.isLoading ||
          isLoading ||
          getAppearanceInfo?.isLoading
        }
      />
      <Grid container>
        <Grid size={{ xs: 4, md: 4, lg: 3, xl: 2 }}>
          <List>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => setSelectedIndex(0)}
                sx={{ background: selectedIndex === 0 ? " #F9F9FF" : "white" }}
              >
                <ListItemText
                  className="assistantSubList"
                  primary="General"
                  sx={{
                    "& span": {
                      color: selectedIndex === 0 ? " #1D2024" : "#61646b",
                      fontWeight:
                        selectedIndex === 0 ? "600 !important" : "500",
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  setSelectedIndex(1);
                  getAppearance({ chatbot_id: bot?.id });
                }}
                sx={{ background: selectedIndex === 1 ? " #F9F9FF" : "white" }}
              >
                <ListItemText
                  primary="Appearance"
                  className="assistantSubList"
                  sx={{
                    "& span": {
                      color: selectedIndex === 1 ? " #1D2024" : "#61646b",
                      fontWeight:
                        selectedIndex === 1 ? "600 !important" : "500",
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  setSelectedIndex(2);
                }}
                sx={{ background: selectedIndex === 2 ? " #F9F9FF" : "white" }}
              >
                <ListItemText
                  primary="Messaging"
                  className="assistantSubList"
                  sx={{
                    "& span": {
                      color: selectedIndex === 2 ? " #1D2024" : "#61646b",
                      fontWeight:
                        selectedIndex === 2 ? "600 !important" : "500",
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  setSelectedIndex(3);
                }}
                sx={{ background: selectedIndex === 3 ? " #F9F9FF" : "white" }}
              >
                <ListItemText
                  primary="Starter Question"
                  className="assistantSubList"
                  sx={{
                    "& span": {
                      color: selectedIndex === 3 ? " #1D2024" : "#61646b",
                      fontWeight:
                        selectedIndex === 3 ? "600 !important" : "500",
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  setSelectedIndex(4);
                }}
                sx={{ background: selectedIndex === 4 ? " #F9F9FF" : "white" }}
              >
                <ListItemText
                  primary="Email Setup"
                  className="assistantSubList"
                  sx={{
                    "& span": {
                      color: selectedIndex === 4 ? " #1D2024" : "#61646b",
                      fontWeight:
                        selectedIndex === 4 ? "600 !important" : "500",
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  setSelectedIndex(5);
                }}
                sx={{ background: selectedIndex === 5 ? " #F9F9FF" : "white" }}
              >
                <ListItemText
                  primary="Usage and Security"
                  className="assistantSubList"
                  sx={{
                    "& span": {
                      color: selectedIndex === 5 ? " #1D2024" : "#61646b",
                      fontWeight:
                        selectedIndex === 5 ? "600 !important" : "500",
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  setSelectedIndex(6);
                }}
                sx={{ background: selectedIndex === 6 ? " #F9F9FF" : "white" }}
              >
                <ListItemText
                  primary="User forms"
                  className="assistantSubList"
                  sx={{
                    "& span": {
                      color: selectedIndex === 6 ? " #1D2024" : "#61646b",
                      fontWeight:
                        selectedIndex === 6 ? "600 !important" : "500",
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  setSelectedIndex(7);
                }}
                sx={{ background: selectedIndex === 7 ? " #F9F9FF" : "white" }}
              >
                <ListItemText
                  primary="Working hours"
                  className="assistantSubList"
                  sx={{
                    "& span": {
                      color: selectedIndex === 7 ? " #1D2024" : "#61646b",
                      fontWeight:
                        selectedIndex === 7 ? "600 !important" : "500",
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Grid>
        <ThemeProvider theme={assistanceTheme}>
          <Grid size={{ xs: 8, md: 8, lg: 9, xl: 10 }}>
            {selectedIndex === 0 && (
              <form id="general" onSubmit={handleSubmit(handleSave)}>
                <General control={control} setValue={setValue} />
              </form>
            )}
            {selectedIndex === 1 && (
              <form
                id="appearance"
                onSubmit={submitApperance(handleSaveAppearance)}
              >
                <Appearance
                  selectedColor={selectedColor}
                  setSelectedColor={setSelectedColor}
                  selectedWidget={selectedWidget}
                  setSelectedWidget={setSelectedWidget}
                  control={controlAppearance}
                />
              </form>
            )}
            {(() => {
              switch (selectedIndex) {
                case 2:
                  return <Messaging />;
                case 3:
                  return <StarterQuestion />;
                case 4:
                  return <EmailSetup />;
                case 5:
                  return <UsageSecurity />;
                case 6:
                  return <UserForm />;
                case 7:
                  return <WorkingHours />;

                default:
                  return "";
              }
            })()}
          </Grid>
        </ThemeProvider>
      </Grid>
      <Grid
        container
        marginTop={"20px"}
        justifyContent={"space-between"}
        padding={"17px 0px 17px 32px"}
        borderTop=" 1px solid #E5E7EB"
        marginLeft={"-32px"}
      >
        <Grid size={2}>
          <Buttons
            type="secondary"
            text="Discard Changes"
            onClick={handleDiscard}
          />
        </Grid>
        <Grid size={2}>
          <Buttons
            type="primary"
            buttonType="submit"
            text="Save Changes"
            form={getForm()}
            // onClick={handleSave}
          />
        </Grid>
      </Grid>
    </>
  );
};
