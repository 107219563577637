import { Outlet, useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid2";
import { Box } from "@mui/material";
import { MessageAlert } from "../common/snackBar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setAlert } from "../../redux/features/alert/alertSlice";
import { UiHeader } from "./uiHeader";
import Sidebar from "./sidebar";
import { Page } from "../../routes/config";
export const Header = () => {
  const { open, message } = useSelector((state: any) => state.alert);

  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      dispatch(setAlert({ open: false, alertType: "", message: message }));
    }, 5000);
  }, [open]);
  return (
    <div style={{ display: "flex", height: "100%" }}>
      {location?.pathname !== Page.CHAT && (
        <div style={{ width: "240px", background: "#7A72E14D" }}>
          <Sidebar />
        </div>
      )}
      <div
        style={{
          width:
            location?.pathname !== Page.CHAT ? "calc(100% - 242px)" : "100%",
        }}
      >
        {location?.pathname !== Page.CHAT && <UiHeader />}

        <MessageAlert></MessageAlert>
        <div
          style={{
            padding: "32px",
            overflow: "auto",
            height: "100vh",
            paddingBottom: location?.pathname === Page.CHAT ? "76px" : "92px",
          }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};
