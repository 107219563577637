import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Page } from "../../routes/config";
import "./style.css";
import {
  blackSettings,
  chart,
  connect,
  equalizer,
  home,
  integration,
  message,
  rightArrow,
  settings,
  share,
  whiteConnect,
  whiteIntegration,
} from "../../assets/icons";
import {
  clearAssistantState,
  setAssistantSidebar,
} from "../../redux/features/assistant/assistantSlice";
import { bot } from "../../assets/images";
import { clearAlertState } from "../../redux/features/alert/alertSlice";
import { clearAuthState, setHeader } from "../../redux/features/auth/authSlice";

const Sidebar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sidebarState = useSelector((state: any) => state?.assistant?.create);
  const botName = useSelector((state: any) => state?.assistant?.name);

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname === Page.DASHBOARD) {
      dispatch(setAssistantSidebar(false));
      dispatch(setHeader("Dashboard"));
    } else if (pathname === Page.SETTINGS) {
      dispatch(setHeader("Settings"));
    } else if (pathname === Page.SOURCES) {
      dispatch(setHeader("Sources"));
    } else if (pathname === Page.CONNECT) {
      dispatch(setHeader("Connect"));
    } else if (pathname === Page.INTEGRATION) {
      dispatch(setHeader("Integration"));
    }
  }, [location.pathname]);
  const handleLogout = () => {
    dispatch(clearAlertState());
    dispatch(clearAssistantState());
    dispatch(clearAuthState());
  };
  return (
    <>
      <div className="sideBarMain">
        <List component="nav" sx={{ height: "100%" }}>
          <div
            style={{ display: sidebarState ? "flex" : "grid" }}
            className="logoContainer"
            onClick={() => navigate(Page.DASHBOARD)}
          >
            {sidebarState ? (
              <>
                <img src={bot}></img>
                <span className="logo">{botName}</span>
              </>
            ) : (
              <>
                <span className="logo">Virat</span>
                <span className="logoSub">
                  Private workspace
                  <img className="arrowImg" src={rightArrow}></img>
                </span>
              </>
            )}
          </div>
          <div className="scrollSidebar">
            {sidebarState ? (
              //  /* -----------------create Sidebar------------------------------------------------- */

              <div className="sidebarItemsContainer">
                <ListItem

                // component={Link}
                // to={Page.Customers}
                // selected={selectedIndex === 0}
                // className="sideBarItem"
                // style={{
                //   color: selectedIndex === 0 ? "#22A06B" : "#6C6C6C",
                //   background: selectedIndex === 0 ? "#F7F7F7" : "transparent",
                //   borderRight:
                //     selectedIndex === 0 ? "3px solid #22A06B" : "none",
                // }}
                >
                  <ListItemIcon sx={{ minWidth: "18px", marginRight: "12px" }}>
                    <img src={share} alt="home" />
                  </ListItemIcon>
                  <ListItemText primary="Training" className="listTextCss" />
                </ListItem>
                <ListItem
                  // button
                  component={Link}
                  to={Page.SOURCES}
                  // selected={selectedIndex === 1}
                  // className="sideBarItem"
                  style={{
                    background:
                      location?.pathname === Page.SOURCES
                        ? " #7A72E1"
                        : "transparent",
                    borderRadius: "6px",
                  }}
                >
                  <ListItemIcon
                    sx={{ minWidth: "18px", marginRight: "12px" }}
                  ></ListItemIcon>
                  <ListItemText
                    primary="Sources"
                    className="listTextCss"
                    sx={{
                      color:
                        location?.pathname === Page.SOURCES
                          ? "white"
                          : " #4f4f4f",
                    }}
                  />
                </ListItem>
                <ListItem
                // button
                // component={Link}
                // to={Page.Transporters}
                // selected={selectedIndex === 1}
                // className="sideBarItem"
                // style={{
                //   color: selectedIndex === 1 ? "#22A06B" : "#6C6C6C",
                //   background: selectedIndex === 1 ? "#F7F7F7" : "transparent",
                //   borderRight:
                //     selectedIndex === 1 ? "3px solid #22A06B" : "none",
                // }}
                >
                  <ListItemIcon
                    sx={{ minWidth: "18px", marginRight: "12px" }}
                  ></ListItemIcon>
                  <ListItemText primary="Guidelines" className="listTextCss" />
                </ListItem>
                <ListItem
                // button
                // component={Link}
                // to={Page.Transporters}
                // selected={selectedIndex === 1}
                // className="sideBarItem"
                // style={{
                //   color: selectedIndex === 1 ? "#22A06B" : "#6C6C6C",
                //   background: selectedIndex === 1 ? "#F7F7F7" : "transparent",
                //   borderRight:
                //     selectedIndex === 1 ? "3px solid #22A06B" : "none",
                // }}
                >
                  <ListItemIcon
                    sx={{ minWidth: "18px", marginRight: "12px" }}
                  ></ListItemIcon>
                  <ListItemText primary="Workflows" className="listTextCss" />
                </ListItem>
                <ListItem
                // button
                // component={Link}
                // to={Page.Transporters}
                // selected={selectedIndex === 1}
                // className="sideBarItem"
                // style={{
                //   color: selectedIndex === 1 ? "#22A06B" : "#6C6C6C",
                //   background: selectedIndex === 1 ? "#F7F7F7" : "transparent",
                //   borderRight:
                //     selectedIndex === 1 ? "3px solid #22A06B" : "none",
                // }}
                >
                  <ListItemIcon
                    sx={{ minWidth: "18px", marginRight: "12px" }}
                  ></ListItemIcon>
                  <ListItemText primary="Actions" className="listTextCss" />
                </ListItem>
                <ListItem
                  // button
                  component={Link}
                  to={Page.SETTINGS}
                  // selected={selectedIndex === 1}
                  // className="sideBarItem"
                  style={{
                    background:
                      location?.pathname === Page.SETTINGS
                        ? " #7A72E1"
                        : "transparent",
                    borderRadius: "6px",
                  }}
                >
                  <ListItemIcon sx={{ minWidth: "18px", marginRight: "12px" }}>
                    {location?.pathname === Page.SETTINGS ? (
                      <img src={settings} />
                    ) : (
                      <img src={blackSettings}></img>
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary="Settings"
                    className="listTextCss"
                    sx={{
                      color:
                        location?.pathname === Page.SETTINGS
                          ? "white"
                          : " #4f4f4f",
                    }}
                  />
                </ListItem>
                <ListItem
                  // button
                  component={Link}
                  to={Page.INTEGRATION}
                  // selected={selectedIndex === 1}
                  // className="sideBarItem"
                  style={{
                    background:
                      location?.pathname === Page.INTEGRATION
                        ? " #7A72E1"
                        : "transparent",
                    borderRadius: "6px",
                  }}
                >
                  <ListItemIcon sx={{ minWidth: "18px", marginRight: "12px" }}>
                    {location?.pathname === Page.INTEGRATION ? (
                      <img src={whiteIntegration} />
                    ) : (
                      <img src={integration} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary="Integrations"
                    className="listTextCss"
                    sx={{
                      color:
                        location?.pathname === Page.INTEGRATION
                          ? "white"
                          : " #4f4f4f",
                    }}
                  />
                </ListItem>
                <ListItem
                  // button
                  component={Link}
                  to={Page.CONNECT}
                  // selected={selectedIndex === 1}
                  // className="sideBarItem"
                  style={{
                    background:
                      location?.pathname === Page.CONNECT
                        ? " #7A72E1"
                        : "transparent",
                    borderRadius: "6px",
                  }}
                >
                  <ListItemIcon sx={{ minWidth: "18px", marginRight: "12px" }}>
                    {location?.pathname === Page.CONNECT ? (
                      <img src={whiteConnect} />
                    ) : (
                      <img src={connect}></img>
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary="Connect"
                    className="listTextCss"
                    sx={{
                      color:
                        location?.pathname === Page.CONNECT
                          ? "white"
                          : " #4f4f4f",
                    }}
                  />
                </ListItem>
                {/* <ListItem
                // button
                // component={Link}
                // to={Page.Transporters}
                // selected={selectedIndex === 1}
                // className="sideBarItem"
                // style={{
                //   color: selectedIndex === 1 ? "#22A06B" : "#6C6C6C",
                //   background: selectedIndex === 1 ? "#F7F7F7" : "transparent",
                //   borderRight:
                //     selectedIndex === 1 ? "3px solid #22A06B" : "none",
                // }}
                >
                  <ListItemIcon sx={{ minWidth: "18px", marginRight: "12px" }}>
                    <img src={message} />
                  </ListItemIcon>
                  <ListItemText primary="Inbox" className="listTextCss" />
                </ListItem> */}
                <ListItem
                // button
                // component={Link}
                // to={Page.Transporters}
                // selected={selectedIndex === 1}
                // className="sideBarItem"
                // style={{
                //   color: selectedIndex === 1 ? "#22A06B" : "#6C6C6C",
                //   background: selectedIndex === 1 ? "#F7F7F7" : "transparent",
                //   borderRight:
                //     selectedIndex === 1 ? "3px solid #22A06B" : "none",
                // }}
                >
                  <ListItemIcon sx={{ minWidth: "18px", marginRight: "12px" }}>
                    <img src={chart} />
                  </ListItemIcon>
                  <ListItemText primary="Analytics" className="listTextCss" />
                </ListItem>
              </div>
            ) : (
              // --------------------------------------regular sidebar---------------------------------------

              <div className="sidebarItemsContainer">
                <ListItem
                  component={Link}
                  to={Page.DASHBOARD}
                  // selected={selectedIndex === 0}
                  // className="sideBarItem"
                  style={{
                    background:
                      location?.pathname === Page.DASHBOARD
                        ? " #7A72E1"
                        : "transparent",
                    borderRadius: "6px",
                  }}
                >
                  <ListItemIcon sx={{ minWidth: "18px", marginRight: "12px" }}>
                    <img
                      src={home}
                      alt="home"
                      style={{
                        color:
                          location?.pathname === Page.DASHBOARD
                            ? "white"
                            : "black",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Home"
                    className="listTextCss"
                    sx={{
                      color:
                        location?.pathname === Page.DASHBOARD
                          ? "white"
                          : " #4f4f4f",
                    }}
                  />
                </ListItem>
                <ListItem
                // button
                // component={Link}
                // to={Page.Transporters}
                // selected={selectedIndex === 1}
                // className="sideBarItem"
                // style={{
                //   color: selectedIndex === 1 ? "#22A06B" : "#6C6C6C",
                //   background: selectedIndex === 1 ? "#F7F7F7" : "transparent",
                //   borderRight:
                //     selectedIndex === 1 ? "3px solid #22A06B" : "none",
                // }}
                >
                  <ListItemIcon sx={{ minWidth: "18px", marginRight: "12px" }}>
                    <img src={equalizer} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Api and Integrations"
                    className="listTextCss"
                  />
                </ListItem>
              </div>
            )}
            <div className="logoutContainer">
              {/* <ListItem
              // button
              // component={Link}
              // to={Page.LOGIN}
              // selected={selectedIndex === 5}
              // className="sideBarItem logOut"
              // onClick={handleLogoutClick}
              >
                <ListItemIcon sx={{ minWidth: "18px", marginRight: "12px" }}>
                  <img src={headphone} />
                </ListItemIcon>
                <ListItemText primary="Help Center" className="listTextCss" />
              </ListItem> */}
              {/* <ListItem
              // button
              // component={Link}
              // to={Page.LOGIN}
              // selected={selectedIndex === 5}
              // className="sideBarItem logOut"
              // onClick={handleLogoutClick}
              > */}
              {/* <ListItemIcon>
                  <img src={logOut} alt="logout" />
                </ListItemIcon> */}
              {/* <ListItemText primary="Contact Sales" className="listTextCss" />
              </ListItem> */}
              {/* <ListItem
              // button
              // component={Link}
              // to={Page.LOGIN}
              // selected={selectedIndex === 5}
              // className="sideBarItem logOut"
              // onClick={handleLogoutClick}
              >
                <ListItemIcon sx={{ minWidth: "18px", marginRight: "12px" }}>
                  <img src={support} />
                </ListItemIcon>
                <ListItemText
                  primary="24x7 Live AI Support"
                  className="listTextCss"
                />
              </ListItem> */}
              {/* <ListItem
              // button
              // component={Link}
              // to={Page.LOGIN}
              // selected={selectedIndex === 5}
              // className="sideBarItem logOut"
              // onClick={handleLogoutClick}
              > */}
              {/* <ListItemIcon>
                  <img src={logOut} alt="logout" />
                </ListItemIcon> */}
              {/* <ListItemText
                  primary="Submit a Ticket"
                  className="listTextCss"
                />
              </ListItem> */}
              <ListItem
                // button
                // component={Link}
                // to={Page.LOGIN}
                // selected={selectedIndex === 5}
                // className="sideBarItem logOut"
                onClick={handleLogout}
                sx={{ cursor: "pointer" }}
              >
                {/* <ListItemIcon>
                  <img src={} alt="logout" />
                </ListItemIcon> */}
                <ListItemText primary="Logout" className="listTextCss" />
              </ListItem>
            </div>
          </div>
        </List>
      </div>
    </>
  );
};

export default Sidebar;
