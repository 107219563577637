import { useEffect, useState } from "react";
import { Container, Typography, Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import "./style.css";
import { animation, robo } from "../../../assets/images";
import Buttons from "../../common/button";
import { Controller, useForm } from "react-hook-form";
import { RegExpressions } from "../../../constants/regExp";
import { Input, PasswordInput } from "../../common/input";
import { useLoginUserMutation } from "../../../redux/services/loginApiSlice";
import { useDispatch } from "react-redux";
import { setAuthInfo, setLogin } from "../../../redux/features/auth/authSlice";
import SignUp from "./signUp";
import { LoginHeader } from "../../layout/loginHeader";
import { useNavigate } from "react-router-dom";
import { Page } from "../../../routes/config";
import { setAlert } from "../../../redux/features/alert/alertSlice";
import { Loader } from "../../common/loader";
import { handleErrors } from "../../../utils";
import dayjs from "dayjs";

export const SignIn = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [signUp, setSignUp] = useState(false);
  const [loginUser, loginUserInfo] = useLoginUserMutation<any>();
  const onSubmit = (data: any) => {
    loginUser(data);
  };
  useEffect(() => {
    if (loginUserInfo?.isSuccess) {
      const { access_token, user_id } = loginUserInfo?.data;
      dispatch(
        setAuthInfo({
          accessToken: access_token,
          userId: user_id,
        })
      );
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "Logged in successfully",
        })
      );
      dispatch(setLogin(true));
      navigate(Page.DASHBOARD);
    }
  }, [loginUserInfo?.isSuccess]);
  useEffect(() => {
    if (loginUserInfo?.isError) {
      handleErrors(loginUserInfo, dispatch);
    }
  }, [loginUserInfo?.isError]);

  return (
    <>
      <Loader loader={loginUserInfo?.isLoading} />
      <Grid container sx={{ height: "100vh" }}>
        <Grid size={12}>
          <LoginHeader />
        </Grid>
        <Grid
          size={12}
          sx={{
            overflow: "auto",
            height: "100%",
            marginTop: "8px",
          }}
        >
          <Grid container spacing={4} height={"100%"}>
            {/* Left Section */}
            <Grid
              size={{ xs: 12, md: 7 }}
              sx={{
                background: "#F9F9FF",
                padding: "90px 115px",
                paddingBottom: "20px",
              }}
            >
              <Typography className="heading">
                {signUp ? "Join Virat Today!" : "Welcome to Virat!"}
              </Typography>
              <Typography className="secondaryText">
                {signUp
                  ? "Build chatbots, analyze enterprise data, and automate workflows with powerful AI tools at your fingertips."
                  : "You are one step away from creating awesome chatbots and automate workflows with powerful AI tools"}
              </Typography>
              <Box
                component="img"
                src={animation}
                alt="Robot"
                sx={{ width: "80%", marginTop: "-25px" }}
              />
            </Grid>

            {/* Right Section */}
            <Grid
              size={{ xs: 12, md: 5 }}
              sx={{
                padding: "18px 100px",
                paddingBottom: signUp ? "7rem" : "20px",
              }}
            >
              <Grid container justifyContent={"center"}>
                <Grid size={12}>
                  {signUp ? (
                    <SignUp signUp={signUp} setSignUp={setSignUp} />
                  ) : (
                    <Box
                      sx={{
                        textAlign: "left",
                      }}
                    >
                      <Typography className="signIn">Sign In</Typography>
                      {/* Sign-In Form */}
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                          render={({
                            field: { ref, ...field },
                            fieldState: { invalid, error },
                          }) => (
                            <>
                              <Input
                                {...field}
                                label="Email Id"
                                // placeholder={"Email id"}
                                fullWidth
                                required={true}
                                helperText={
                                  error?.message ? error?.message : " "
                                }
                                value={field.value}
                                error={Boolean(error)}
                                onChange={field.onChange}
                              />
                            </>
                          )}
                          name="email"
                          control={control}
                          rules={{
                            required: "Please enter your email Id",
                            pattern: {
                              value: RegExpressions?.Email,
                              message: "Please enter valid email",
                            },
                          }}
                        />
                        <Controller
                          render={({
                            field: { ref, ...field },
                            fieldState: { invalid, error },
                          }) => (
                            <>
                              <PasswordInput
                                {...field}
                                label="Password"
                                // placeholder={"Password"}
                                value={field.value}
                                error={Boolean(error)}
                                required={true}
                                onChange={field.onChange}
                                helperText={
                                  error?.message ? error?.message : ""
                                }
                                variant="standard"
                                fullWidth
                              />
                            </>
                          )}
                          name="password"
                          control={control}
                          rules={{
                            required: "Please enter your password",
                          }}
                        />
                        {/* <Typography
                        variant="body2"
                        align="right"
                        sx={{ color: "#696969", cursor: "pointer" }}
                      >
                        Forgot Password?
                      </Typography> */}
                        <Buttons
                          variant="contained"
                          type="primary"
                          sx={{
                            marginTop: "64px",
                            height: "45px",
                          }}
                          text={"Sign In"}
                          buttonType={"submit"}
                        ></Buttons>
                      </form>
                      {/* <Divider>OR</Divider>
                <Button
                  variant="outlined"
                  startIcon={<GoogleIcon />}
                  sx={{ color: "gray", borderColor: "gray" }}
                  fullWidth
                >
                  Sign in with Google
                </Button> */}
                      {/* <Typography
                        variant="body2"
                        sx={{ marginTop: "1rem", color: "#696969" }}
                      >
                        New to Virat?{" "}
                        <span
                          onClick={() => setSignUp(true)}
                          style={{ color: "#2200FF", cursor: "pointer" }}
                        >
                          Create new Account
                        </span>
                      </Typography> */}
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid size={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px 60px",
              position: "absolute",
              bottom: "0px",
              width: "100%",
              backgroundColor: "white",
              boxShadow: "0px 1px 2px 0px #0000004D",
            }}
          >
            <span className="bookmark">© {dayjs().format("YYYY")} Virat</span>
            <span className="bookmark">
              About &nbsp;&nbsp; Support &nbsp;&nbsp; Contact Us
            </span>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
