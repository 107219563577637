import { Box, Chip, Paper, Switch, Typography } from "@mui/material";
import Button from "../../../common/button";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import { Table } from "../../../common/table";
import {
  useGetFilesQuery,
  useTrainBotMutation,
  useUploadFileMutation,
} from "../../../../redux/services/botApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../../redux/features/alert/alertSlice";
import { deleteIcon } from "../../../../assets/icons";
import { Loader } from "../../../common/loader";
import { bot } from "../../../../assets/images";
import Buttons from "../../../common/button";
import { Input } from "../../../common/input";
import AddIcon from "@mui/icons-material/Add";
import { SelectInput } from "../../../common/selectInput";
import SyncIcon from "@mui/icons-material/Sync";

export const Files = () => {
  const [files, setFiles] = useState<any>([]);
  const [rowData, setRowData] = useState([]);
  const formData = new FormData();
  const chatbotId = useSelector((state: any) => state?.assistant?.id);
  const dispatch = useDispatch();

  const [uploadFile, uploadFileInfo] = useUploadFileMutation<any>();
  const [trainBot, trainBotInfo] = useTrainBotMutation<any>();
  const { data, isSuccess, isLoading, isError, isFetching, refetch } =
    useGetFilesQuery<any>({
      id: chatbotId,
    });

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    const filesArray = Array.from(droppedFiles);
    setFiles((prev: any) => [...prev, ...filesArray]);
    console.log("Files dropped:", droppedFiles);
  };

  const handleFileSelect = (event: any) => {
    const selectedFiles = event.target.files;
    const filesArray = Array.from(selectedFiles);
    setFiles((prev: any) => [...prev, ...filesArray]);
    console.log("Files selected:", selectedFiles);
  };

  const handleUpload = () => {
    console.log("Uploading files:", files);
    formData.append("chatbot_id", chatbotId);
    if (files.length > 0) {
      files.forEach((file: File, index: number) => {
        formData.append(`files`, file);
      });
    }

    uploadFile(formData);
  };
  useEffect(() => {
    if (uploadFileInfo?.isSuccess) {
      trainBot({
        chatbot_id: chatbotId,
      });
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "File uploaded successfully",
        })
      );
    }
  }, [uploadFileInfo?.isSuccess]);
  useEffect(() => {
    if (trainBotInfo?.isSuccess) {
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "Assistant trained successfully",
        })
      );
      setFiles([]);
      refetch();
    }
  }, [trainBotInfo?.isSuccess]);
  const handleDeleteFile = (index: number) => {
    setFiles((prev: any[]) => prev.filter((_, i) => i !== index));
  };
  useEffect(() => {
    if (isSuccess) {
      const updatedFileNames = data?.file_names.map(
        (file: any, index: any) => ({
          ...file,
          id: index + 1,
        })
      );

      setRowData(updatedFileNames);
    }
  }, [isSuccess, data]);
  const columns: any = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <>
            <p className="slice">{params?.value}</p>
          </>
        );
      },
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 150,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <>
            <p className="slice">{params?.row?.name.split(".")[1]}</p>
          </>
        );
      },
    },
    {
      field: "date_trained",
      headerName: "Date",
      minWidth: 150,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <>
            <p className="slice">{params?.value}</p>
          </>
        );
      },
    },
    {
      field: "tokens_consumed",
      headerName: "Token Consumed",
      minWidth: 150,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <>
            <p className="slice">{params?.value === 0 ? "" : params?.value}</p>
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "left",
      cellClassName: "cell-padding",
      align: "left",
      flex: 1,
      minWidth: 150,
      sortable: false,

      renderCell: (params: any) => (
        <Chip label={"Trained"} className={"enableChip"} />
      ),
    },
  ];
  return (
    <>
      <Loader
        loader={
          isFetching ||
          isLoading ||
          uploadFileInfo?.isLoading ||
          trainBotInfo?.isLoading
        }
      />
      <Box sx={{ padding: "20px" }}>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "600",
            letterSpacing: "-0.08399999886751175px",
            color: "#000",
            marginBottom: "6px",
          }}
        >
          Upload files to train Assistant on your data
        </Typography>
        <Box
          sx={{
            border: "2px dashed #dcdcdc",
            borderRadius: "8px",
            padding: "20px",
            backgroundColor: "#f4f6f8",
            color: "#555",
            height: "150px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10px",
          }}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <Typography>
            Drag and drop files here or{" "}
            <label style={{ cursor: "pointer", color: "#3f51b5" }}>
              click to upload
              <input type="file" multiple hidden onChange={handleFileSelect} />
            </label>
          </Typography>
        </Box>
        <Grid container>
          {files.length > 0 &&
            files.map((x: any, index: any) => {
              return (
                <Grid size={12}>
                  {x?.name}
                  <img
                    style={{ cursor: "pointer", marginLeft: "20px" }}
                    src={deleteIcon}
                    onClick={() => handleDeleteFile(index)}
                  ></img>
                </Grid>
              );
            })}
        </Grid>
        <Grid container>
          <Grid size={2}>
            <Button
              type="primary"
              sx={{ marginTop: "20px" }}
              onClick={handleUpload}
              disabled={files.length === 0}
              text={"Upload and train"}
            ></Button>
          </Grid>
        </Grid>
        <Grid container sx={{ border: "1px solid #DDDDE2" }} marginTop={5}>
          <Grid
            size={12}
            sx={{
              borderBottom: "1px solid #DDDDE2",
              padding: "10px 20px",
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "600",
              letterSpacing: "-0.08399999886751175px",
              color: "#000",
            }}
          >
            Files
          </Grid>
          <Grid size={12}>
            <div className="botTable">
              <Table
                rows={rowData}
                columns={columns}
                isPagination={true}
                //   onRowClick={handleRowClick}
                //   pagination={pagination}
                //   onPaginationModelChange={handlePaginationModelChange}
                rowCount={10}
                //   tableHeight={tableHeight}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export const Apps = () => {
  return (
    <>
      <Grid container sx={{ padding: "20px" }}>
        <Grid size={12} marginBottom={"20px"}>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "600",
              letterSpacing: "-0.08399999886751175px",
              color: "#000",
              marginBottom: "6px",
            }}
          >
            Import files with ease and ensure they are on sync
          </Typography>
        </Grid>
        <Grid
          size={12}
          display={"flex"}
          gap={"40px"}
          sx={{ border: "1px solid #DDDDE2" }}
          padding={"14px 20px"}
          borderRadius={"8px"}
        >
          <div>
            <img src={bot}></img>
          </div>
          <div style={{ display: "grid", width: "100%" }}>
            <span
              style={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "600",
                letterSpacing: "-0.08399999886751175px",
                color: "#000",
                marginBottom: "6px",
              }}
            >
              Google Drive
            </span>
            <span
              style={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "500",
                color: "#61646B",
                marginBottom: "6px",
              }}
            >
              Ingest data from Google Drive
            </span>
          </div>
          <div>
            <Buttons type="primary" text={"Connect"} sx={{ width: "80px" }} />
          </div>
        </Grid>
      </Grid>
    </>
  );
};
export const Links = () => {
  const columns: any = [
    {
      field: "links",
      headerName: "Links",
      minWidth: 150,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <>
            <p className="slice flex">{params?.value}</p>
          </>
        );
      },
    },
    {
      field: "visibility",
      headerName: "Visibility",
      headerAlign: "left",
      align: "left",
      minWidth: 150,
      cellClassName: "cell-padding",
      sortable: false,
      flex: 1.5,
      renderCell: (params: any) => {
        return (
          <>
            <Switch defaultChecked />
          </>
        );
      },
    },
  ];
  const options = [
    {
      text: "off",
      value: "off",
    },
    {
      text: "every 2 days",
      value: "every 2 days",
    },
    {
      text: "every weeks",
      value: "every weeks",
    },
    {
      text: "every 2 weeks",
      value: "every 2 weeks",
    },
    {
      text: "every 4 weeks",
      value: "every 4 weeks",
    },
  ];
  const trainedColumn: any = [
    {
      field: "links",
      headerName: "Links",
      minWidth: 150,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <>
            <p className="slice flex">{params?.value}</p>
          </>
        );
      },
    },
    {
      field: "visibility",
      headerName: "Visibility",
      headerAlign: "left",
      align: "left",
      minWidth: 150,
      cellClassName: "cell-padding",
      sortable: false,
      flex: 1.5,
      renderCell: (params: any) => {
        return (
          <>
            <Switch defaultChecked />
          </>
        );
      },
    },
    {
      field: "character",
      headerName: "Characters",
      headerAlign: "left",
      align: "left",
      minWidth: 150,
      cellClassName: "cell-padding",
      sortable: false,
      flex: 1.5,
      renderCell: (params: any) => {
        return (
          <>
            <p className="slice flex">{params?.value}</p>
          </>
        );
      },
    },
    {
      field: "lastSynced",
      headerName: "Last Synced",
      headerAlign: "left",
      align: "left",
      minWidth: 150,
      cellClassName: "cell-padding",
      sortable: false,
      flex: 1.5,
      renderCell: (params: any) => {
        return (
          <>
            <Switch defaultChecked />
          </>
        );
      },
    },
    {
      field: "autoSync",
      headerName: "Auto Sync",
      headerAlign: "left",
      align: "left",
      minWidth: 150,
      cellClassName: "cell-padding",
      sortable: false,
      flex: 1.5,
      renderCell: (params: any) => {
        return (
          <>
            <SelectInput
              placeholder={"Select AI Model"}
              fullWidth
              value={"off"}
              optionValue={options}
            />
          </>
        );
      },
    },
    {
      field: "action",
      headerAlign: "left",
      align: "left",
      minWidth: 150,
      cellClassName: "cell-padding",
      sortable: false,
      flex: 1.5,
      renderCell: (params: any) => {
        return (
          <>
            <SyncIcon />
          </>
        );
      },
    },
  ];
  return (
    <>
      <Box sx={{ padding: "20px" }}>
        <Grid container>
          <Grid size={6}>
            <Paper sx={{ padding: "25px" }}>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "600",
                  letterSpacing: "-0.08399999886751175px",
                  color: "#000",
                  marginBottom: "6px",
                }}
              >
                Crawl Webpages
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#61646B",
                  marginBottom: "12px",
                }}
              >
                Provide us with a top-level domain, and we'll identify all
                linked pages.
              </Typography>
              <Grid container spacing={2}>
                <Grid size={9}>
                  <Input />
                </Grid>
                <Grid size={3}>
                  <Buttons type="primary" text="Continue" />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid size={6}>
            <Paper sx={{ padding: "25px" }}>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "600",
                  letterSpacing: "-0.08399999886751175px",
                  color: "#000",
                  marginBottom: "6px",
                }}
              >
                Submit a XML sitemap
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#61646B",
                  marginBottom: "12px",
                }}
              >
                We'll conduct a comprehensive crawl of your entire website.
              </Typography>
              <Grid container spacing={2}>
                <Grid size={9}>
                  <Input />
                </Grid>
                <Grid size={3}>
                  <Buttons type="primary" text="Load Sitemap" />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container marginTop={"10px"}>
          <Paper sx={{ padding: "25px", width: "100%" }}>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "600",
                letterSpacing: "-0.08399999886751175px",
                color: "#000",
                marginBottom: "6px",
              }}
            >
              Add Individual Links
            </Typography>

            <Input />
            <Grid size={12} marginTop={"10px"}>
              <Grid container>
                <Grid size={10} alignContent={"center"}>
                  <span
                    style={{
                      color: " #5447a9",
                      fontSize: "14px",
                      fontFamily: "Inter",
                      display: "flex",
                    }}
                  >
                    <AddIcon sx={{ width: "16px", height: "16px" }} /> Add more
                  </span>
                </Grid>
                <Grid size={2}>
                  <Buttons type={"primary"} text="submit" />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Paper sx={{ marginTop: "20px" }}>
          <Table
            rows={[]}
            columns={columns}
            isPagination={true}
            // pagination={pagination}
            // onPaginationModelChange={handlePaginationModelChange}
            rowCount={10}
          />
        </Paper>
      </Box>
    </>
  );
};
