import { Tabs, Tab } from "@mui/material";
import { useState } from "react";
import { Apps, Files, Links } from "./files";

export const Sources = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };
  return (
    <>
      <Tabs value={selectedTab} onChange={handleChange}>
        <Tab label="Links" />
        <Tab label="Files" />
        <Tab label="FAQs" disabled />
        <Tab label="Apps" />
        <Tab label="SpreadSheet" disabled />
      </Tabs>
      {selectedTab === 0 && <Links />}
      {selectedTab === 1 && <Files />}
      {selectedTab === 3 && <Apps />}
    </>
  );
};
