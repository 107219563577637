import { Typography, Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import "./style.css";
import Buttons from "../../common/button";
const WorkingHours = () => {
  return (
    <Box p={2}>
      <Grid container>
        <Grid size={12}>
          <Typography>Working hours</Typography>
        </Grid>
        <Grid size={12}>
          <Typography variant="caption">
            Give your customers a better idea of when to expect support by
            setting working hours. Create working hours with your business hours
            and time zones.
            
          </Typography>
        </Grid>
        <Grid size={12}>
          <Grid size={3} sx={{mt:'1rem'}}>
          <Buttons type="primary" text="Create working hours" />
          </Grid>
        </Grid>
      </Grid>
      
    </Box>
  );
};
export default WorkingHours;
