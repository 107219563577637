import {
  FormControl,
  Typography,
  FormControlLabel,
  Switch,
  Box,
} from "@mui/material";
import { Input } from "../../common/input";
import { ChangeEvent, useState } from "react";
import Grid from "@mui/material/Grid2";
import "./style.css";
import { SelectInput } from "../../common/selectInput";

const options = [
  {
    text: "50 Messages per user per day",
    value: "50 Messages per user per day",
  },
];
const UsageSecurity = () => {
  const [accessUser, setAccessUser] = useState<boolean>(false);
  const handleAccessUsers = (event: ChangeEvent<HTMLInputElement>) =>
    setAccessUser(event.target.checked);
  return (
    <Box className="usageSecurity">
      <Grid container>
        <Grid size={12}>
          <Typography>Adjust the usage limit for your users</Typography>
        </Grid>
        <Grid size={12}>
          <FormControl fullWidth margin="normal">
            <FormControlLabel
              className="settingFormLable"
              control={
                <SelectInput
                  placeholder={"Select AI Model"}
                  fullWidth
                  value={"50 Messages per user per day"}
                  optionValue={options}
                />
              }
              label={
                <Grid size={8}>
                  <Typography>Usage limit per user</Typography>
                  <Typography variant="caption">
                    This limit applies to both the widget and messaging apps
                    like WhatsApp, Messenger, and Telegram.
                  </Typography>
                </Grid>
              }
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid size={12}>
        <Typography gutterBottom>User limit warning</Typography>
        <Typography variant="caption">
          Set warning message to show when user limit exceeds
        </Typography>
        <Input />
      </Grid>

      <Grid size={12} sx={{ marginTop: "1rem" }}>
        <Typography>Configure Bot rate limit</Typography>
        <Typography variant="caption">
          Set API rate limit per minute. The maximum rate limit is 300 per
          minute.
        </Typography>
        <Input />
      </Grid>
      <Grid size={12}>
        <FormControl fullWidth margin="normal">
          <FormControlLabel
            className="settingFormLable"
            control={
              <Switch checked={accessUser} onChange={handleAccessUsers} />
            }
            label={
              <>
                <Typography>Access for users with Masked IPs</Typography>
                <Typography variant="caption">
                  Block users with masked IPs from the accessing the chatbot
                </Typography>
              </>
            }
          />
        </FormControl>
      </Grid>
    </Box>
  );
};
export default UsageSecurity;
