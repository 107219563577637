import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  create: false,
  name: "",
  id: "",
};

const assistantSlice: any = createSlice({
  name: "assistant",
  initialState,
  reducers: {
    setAssistantSidebar: (state, { payload }) => {
      state.create = payload;
    },
    setAssistantName: (state, { payload }) => {
      state.name = payload;
    },
    setAssistantId: (state, { payload }) => {
      state.id = payload;
    },
    clearAssistantState: (state) => initialState,
  },
  extraReducers: (builder) => {},
});

export const {
  setAssistantSidebar,
  clearAssistantState,
  setAssistantName,
  setAssistantId,
} = assistantSlice.actions;
export default assistantSlice.reducer;
export const getAssistantState = (state: any) => state?.assistant;
