import "./style.css";
import { Avatar, Drawer, Fab } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid2";
import { useState } from "react";
import Buttons from "../common/button";
import { bot } from "../../assets/images";

export const UiHeader = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<any>(false);
  const headingName = useSelector((state: any) => state?.auth?.header);
  const sidebarState = useSelector((state: any) => state?.assistant?.create);
  const chatbotId = useSelector((state: any) => state?.assistant?.id);
  const chatbotName = useSelector((state: any) => state?.assistant?.name);

  // useEffect(() => {
  //   setPreview(profile);
  // }, [profile]);
  var d = new Date();
  const handlePlaygroundClick = () => {
    const chatState = {
      chatBotId: chatbotId,
      chatBotName: chatbotName,
      form: false,
    };

    const serializedState = encodeURIComponent(JSON.stringify(chatState));
    const chatUrl = `${window.location.origin}/bot/chat?state=${serializedState}`;

    window.open(chatUrl, "_blank", "noopener,noreferrer");
  };
  return (
    <Grid
      container
      sx={{ padding: "22px 32px", borderBottom: "1px solid #E5E7EB" }}
    >
      <Grid size={{ xs: 6 }} className="heading">
        {headingName}
      </Grid>

      <Grid size={{ xs: 6 }}>
        <Grid container justifyContent={"right"} spacing={1.5}>
          {sidebarState ? (
            <>
              <Grid size={3}>
                <Buttons
                  type={"secondary"}
                  text="Playground"
                  onClick={handlePlaygroundClick}
                />
              </Grid>
              {/* <Grid size={3}>
                <Buttons type="secondary" text="Share" start={"upload"} />
              </Grid> */}
              <Grid size={3}>
                <Buttons
                  type="primary"
                  text="Test bot"
                  onClick={() => setOpen(true)}
                />
              </Grid>
              {open && (
                <Drawer
                  open={open}
                  onClose={() => setOpen(false)}
                  anchor="right"
                  sx={{
                    "&: .MuiDrawer-paper": { minWidth: "700px" },
                  }}
                  className="testDialog"
                >
                  <Fab
                    color="primary"
                    aria-label="add"
                    sx={{ position: "fixed", bottom: "16px", right: "16px" }}
                  >
                    <img src={bot}></img>
                  </Fab>
                </Drawer>
              )}
            </>
          ) : (
            <Grid size={3}>
              <Buttons
                type="tertiary"
                variant="outlined"
                text={"watch tutorial"}
              />
            </Grid>
          )}
          <Grid size={1}>
            <Avatar alt={"A"} sx={{ width: "32px", height: "32px" }} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
