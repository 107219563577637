export enum Page {
  LOGIN = "/",
  OTP = "/verifyotp",
  BOTS = "/bot",
  CREATEBOT = "/bot/create",
  DASHBOARD = "/",
  AGENTS = "/agents",
  KNOWLEDGEBASES = "/knowledgebases",
  CHAT = "/bot/chat",
  ASSISTANT = "/assistant/create",
  SETTINGS = "/assistant/create/settings",
  SOURCES = "/assistant/create/sources",
  CONNECT = "/assistant/create/connect",
  INTEGRATION = "/assistant/create/integration",
}
