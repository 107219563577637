import * as React from "react";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import "./style.css";
import { IconButton } from "@mui/material";

const Search = styled("div")({
  borderRadius: "5px",
  height: "40px",
  backgroundColor: "#F7F7F7",
  display: "flex",
  alignItems: "center",
  paddingLeft: "8px",
  "& .MuiInputBase-root": {
    width: "100%",
  },
});

const StyledInputBase = styled(InputBase)({
  "& .MuiInputBase-input": {
    fontFamily: "Poppins",
    fontSize: "96%",
    fontWeight: 400,
    lineHeight: "22px",
    letterSpacing: "1px",
    padding: "0px",
    color: "#5C5F67",
    marginRight: "10px",
  },
});
export const SEARCHBAR = (props: any) => {
  const { value, onChange, setValue, disabled, placeholder } = props;

  return (
    <Search className="search">
      <SearchIcon style={{ color: "#8b8d97" }} className="searchIcon" />

      <StyledInputBase
        placeholder={placeholder ? placeholder : "Search"}
        inputProps={{ "aria-label": "search" }}
        onChange={onChange}
        value={value}
        data-testid="search-bar"
        className="searchTextStyle"
      />
    </Search>
  );
};
