import Grid from "@mui/material/Grid2";
import { SEARCHBAR } from "../../../common/search";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { bot } from "../../../../assets/images";
import { useState } from "react";
export const Integration = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  return (
    <>
      <Grid container>
        <Grid size={8}>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "600",
              letterSpacing: "-0.08399999886751175px",
              color: "#000",
              marginBottom: "6px",
            }}
          >
            All Integrations
          </Typography>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "500",
              color: "#61646B",
              marginBottom: "6px",
            }}
          >
            Supercharge your assistant with ready-to-use integrations
          </Typography>
        </Grid>
        <Grid size={4}>
          <SEARCHBAR />
        </Grid>
      </Grid>
      <Grid container>
        <Grid size={3}>
          {" "}
          <List>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => setSelectedIndex(0)}
                sx={{ background: selectedIndex === 0 ? " #F9F9FF" : "white" }}
              >
                <ListItemText
                  className="assistantSubList"
                  primary="Your apps (0)"
                  sx={{
                    "& span": {
                      color: selectedIndex === 0 ? " #1D2024" : "#61646b",
                      fontWeight:
                        selectedIndex === 0 ? "600 !important" : "500",
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ fontSize: "14px" }}>
              Categories
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  setSelectedIndex(2);
                }}
                sx={{ background: selectedIndex === 2 ? " #F9F9FF" : "white" }}
              >
                <ListItemText
                  primary="All"
                  className="assistantSubList"
                  sx={{
                    "& span": {
                      color: selectedIndex === 2 ? " #1D2024" : "#61646b",
                      fontWeight:
                        selectedIndex === 2 ? "600 !important" : "500",
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  setSelectedIndex(3);
                }}
                sx={{ background: selectedIndex === 3 ? " #F9F9FF" : "white" }}
              >
                <ListItemText
                  primary="Agent & Ticketing"
                  className="assistantSubList"
                  sx={{
                    "& span": {
                      color: selectedIndex === 3 ? " #1D2024" : "#61646b",
                      fontWeight:
                        selectedIndex === 3 ? "600 !important" : "500",
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  setSelectedIndex(4);
                }}
                sx={{ background: selectedIndex === 4 ? " #F9F9FF" : "white" }}
              >
                <ListItemText
                  primary="Messaging Channels"
                  className="assistantSubList"
                  sx={{
                    "& span": {
                      color: selectedIndex === 4 ? " #1D2024" : "#61646b",
                      fontWeight:
                        selectedIndex === 4 ? "600 !important" : "500",
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  setSelectedIndex(5);
                }}
                sx={{ background: selectedIndex === 5 ? " #F9F9FF" : "white" }}
              >
                <ListItemText
                  primary="More Apps"
                  className="assistantSubList"
                  sx={{
                    "& span": {
                      color: selectedIndex === 5 ? " #1D2024" : "#61646b",
                      fontWeight:
                        selectedIndex === 5 ? "600 !important" : "500",
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Grid>
        <Grid size={9}></Grid>
      </Grid>
    </>
  );
};
