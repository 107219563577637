import React, { ChangeEvent, useState, useRef } from "react";
import {
  FormControl,
  Typography,
  FormControlLabel,
  Switch,
  Box,
  Checkbox,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Input } from "../../common/input";
import { SelectInput } from "../../common/selectInput";
import Buttons from "../../common/button";
import Grid from "@mui/material/Grid2";
import "./style.css";

const options = [
  { text: "text", value: "text" },
  { text: "name", value: "name" },
];
interface FieldType {
  index: number;
  fieldtype: string;
}

const UserForm: React.FC = () => {
  const [accessUser, setAccessUser] = useState<boolean>(false);
  const [enableCaptcha, setEnableCaptcha] = useState<boolean>(true);
  const [allFieldTypes, setAllFieldTypes] = useState<FieldType[]>([
    { index: 0, fieldtype: "text" },
  ]);
  const addButtonRef = useRef<HTMLButtonElement>(null);
  const handleFieldType = () => {
    setAllFieldTypes((prevItems) => [
      ...prevItems,
      { index: prevItems.length, fieldtype: "type" },
    ]);
    if (addButtonRef.current) {
      addButtonRef.current.focus();
      addButtonRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };
  const handleSelectChange =
    (index: number) => (event: React.ChangeEvent<{ value: unknown }>) => {
      const newFieldTypes = [...allFieldTypes];
      newFieldTypes[index].fieldtype = event.target.value as string;
      setAllFieldTypes(newFieldTypes);
    };

  const handleDeleteField = (id: number) => {
    setAllFieldTypes((prevItems) =>
      prevItems.filter((_ele, _eleIndex) => _eleIndex !== id)
    );
  };

  const handleEnableCaptcha = (event: ChangeEvent<HTMLInputElement>) =>
    setEnableCaptcha(event.target.checked);

  const handleAccessUsers = (event: ChangeEvent<HTMLInputElement>) =>
    setAccessUser(event.target.checked);

  return (
    <Box className="userForm">
      <Grid container spacing={2}>
        <Grid size={12}>
          <Typography>
            Choose what data to collect from your users before they chat
          </Typography>
        </Grid>

        <Grid size={12}>
          <FormControl fullWidth margin="normal">
            <FormControlLabel
              className="settingFormLable"
              control={
                <Switch checked={accessUser} onChange={handleAccessUsers} />
              }
              label={
                <>
                  <Typography>Enable user form</Typography>
                  <Typography variant="caption">
                    Enable or disable user form
                  </Typography>
                </>
              }
            />
          </FormControl>
        </Grid>

        <Grid size={12}>
          <FormControl fullWidth margin="normal">
            <FormControlLabel
              className="settingFormLable"
              control={
                <Switch
                  checked={enableCaptcha}
                  onChange={handleEnableCaptcha}
                />
              }
              label={
                <>
                  <Typography>Enable captcha</Typography>
                  <Typography variant="caption">
                    Prevent automated spam and fraudulent activities
                  </Typography>
                </>
              }
            />
          </FormControl>
        </Grid>

        <Grid size={12}>
          <form noValidate autoComplete="off">
            {allFieldTypes.length > 0 && (
              <Grid container spacing={2} marginBottom={"0.5rem"}>
                {allFieldTypes.map((field, index) => (
                  <Grid size={12} key={index} className="fieldLabelType">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width={"100%"}
                    >
                      <Grid
                        size={3}
                        sx={{ marginRight: "1rem", marginLeft: "1rem" }}
                      >
                        <SelectInput
                          placeholder="Select text"
                          value={field.fieldtype}
                          onChange={handleSelectChange(index)}
                          optionValue={options}
                          sx={{ height: "1.5rem" }}
                        />
                      </Grid>
                      <Grid size={5} sx={{ marginRight: "1rem" }}>
                        <Input name={`name_${index}` } />
                      </Grid>
                      <Grid
                        size={4}
                        sx={{ display: "flex", justifyContent: "flex-start" }}
                      >
                        <FormControlLabel
                          control={<Checkbox defaultChecked />}
                          label="Required"
                        />
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDeleteField(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}
            <Grid size={12} sx={{ margin: "0.875rem 0" }}>
              <Buttons
                type="primary"
                start="addIcon"
                text="Add new"
                ref={addButtonRef}
                onClick={handleFieldType}
                sx={{ margin: "0.5rem 0", width: "100px" }}
              />
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserForm;
