import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { bot } from "../../../../assets/images";
import { General, Appearance } from "../form";
import { useState } from "react";
import "../style.css";
import { Embed, RestApi } from "./embed";

export const Connect = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  return (
    <>
      <Grid container>
        <Grid size={3}>
          <List>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => setSelectedIndex(0)}
                sx={{ background: selectedIndex === 0 ? " #F9F9FF" : "white" }}
              >
                <ListItemText
                  className="assistantSubList"
                  primary="Embed on your website"
                  sx={{
                    "& span": {
                      color: selectedIndex === 0 ? " #1D2024" : "#61646b",
                      fontWeight:
                        selectedIndex === 0 ? "600 !important" : "500",
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  setSelectedIndex(1);
                }}
                sx={{ background: selectedIndex === 1 ? " #F9F9FF" : "white" }}
              >
                <ListItemText
                  primary="Rest API"
                  className="assistantSubList"
                  sx={{
                    "& span": {
                      color: selectedIndex === 1 ? " #1D2024" : "#61646b",
                      fontWeight:
                        selectedIndex === 1 ? "600 !important" : "500",
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Grid>
        <Grid size={7}>
          {selectedIndex === 0 && <Embed />}
          {selectedIndex === 1 && <RestApi />}
        </Grid>
      </Grid>
    </>
  );
};
