import { Card, Chip, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Buttons from "../../common/button";
import { ellipsis } from "../../../assets/icons";
import { useNavigate } from "react-router-dom";
import { Page } from "../../../routes/config";
import { useDispatch } from "react-redux";
import {
  setAssistantId,
  setAssistantName,
  setAssistantSidebar,
} from "../../../redux/features/assistant/assistantSlice";
import { bot } from "../../../assets/images";
import { useGetAllBotsQuery } from "../../../redux/services/botApiSlice";
import { useEffect, useState } from "react";
import { AssistantModal, CreateDialog } from "./assistantModal";
import { Loader } from "../../common/loader";
import { useGetUsageQuery } from "../../../redux/services/analyticsApiSlice";

export const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [botData, setBotData] = useState([]);
  const [open, setOpen] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
  });
  const [create, setCreate] = useState(false);
  const {
    data,
    isSuccess,
    refetch,
    isLoading,
    fulfilledTimeStamp,
    isFetching,
  }: any = useGetAllBotsQuery({ page: pagination?.page });
  const getUsageQuery: any = useGetUsageQuery({});
  const handleCreateAssistant = () => {
    setCreate(true);
  };
  useEffect(() => {
    if (isSuccess) {
      setBotData(data?.chatbots);
    }
  }, [isSuccess, fulfilledTimeStamp]);
  const handleCardClick = (item: any) => {
    dispatch(setAssistantSidebar(true));
    dispatch(setAssistantName(item?.name));
    dispatch(setAssistantId(item?.id));
    navigate(Page.SETTINGS, { state: { name: item?.name } });
  };
  return (
    <>
      <Loader loader={isFetching || isLoading || getUsageQuery?.isLoading} />
      <Paper sx={{ padding: "25px" }}>
        <Grid container spacing={1}>
          <Grid
            size={12}
            sx={{
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "600",
              letterSpacing: "-0.5px",
              color: "#1D2024",
            }}
          >
            Create new assistant
          </Grid>
          <Grid
            size={12}
            sx={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "500",
              color: "#8B8D97",
            }}
          >
            Check out our detailed docs to learn how to format your data and
            create the perfect assistant for your needs
          </Grid>
          <Grid size={2} marginTop={1}>
            <Buttons
              type="primary"
              variant="contained"
              text="create assistant"
              onClick={handleCreateAssistant}
            />
          </Grid>
        </Grid>
      </Paper>
      <Grid
        container
        marginBottom={"16px"}
        marginTop="32px"
        justifyContent={"space-between"}
      >
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "600",
            color: "#1D2024",
          }}
        >
          Your Assistants
        </Typography>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "400",
            color: "#5447A9",
            cursor: "pointer",
          }}
          onClick={() => setOpen(true)}
        >
          See all
        </Typography>
      </Grid>

      <Grid container spacing={2}>
        {botData.slice(0, 4).map((item: any) => {
          return (
            <Grid size={3}>
              <Card
                sx={{
                  padding: "16px",
                  borderRadius: "12px",
                  border: "1px solid #E5E7EB",
                  background: " #F9F9FA",
                  cursor: "pointer",
                }}
                onClick={() => handleCardClick(item)}
              >
                <Grid container justifyContent={"space-between"}>
                  <img alt="icon" src={bot}></img>
                  <img src={ellipsis}></img>
                </Grid>
                <Grid container>
                  <Grid
                    size={12}
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "600",
                      lineHeight: "20px",
                      letterSpacing: "-0.08399999886751175px",
                      color: "#111827",
                      marginTop: "12px",
                    }}
                  >
                    {item?.name}
                  </Grid>
                  <Grid
                    size={12}
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "500",
                      color: "#8B8D97",
                      marginTop: "12px",
                    }}
                  >
                    Last updated 15 min ago
                  </Grid>
                  <Grid
                    size={12}
                    marginTop={3}
                    display="flex"
                    justifyContent={"space-between"}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "#61646B",
                        alignContent: "center",
                      }}
                    >
                      {item?.total_sessions} chats in 3 days
                    </Typography>
                    <Chip
                      sx={{
                        background: " #7770e11a",
                        borderRadius: "4px",
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontWeight: "500",
                        color: " #7A72E1",
                      }}
                      label={"General Assistant"}
                    ></Chip>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <Typography
        sx={{
          fontFamily: "Inter",
          fontSize: "20px",
          fontWeight: "600",
          marginTop: "32px",
          color: "#1D2024",
          marginBottom: "16px",
        }}
      >
        Workspace Stats
      </Typography>
      <Grid container spacing={1.5}>
        <Grid size={4}>
          <Paper
            sx={{
              border: "1px solid #E5E7EB",
              borderRadius: "8px",
              padding: "17px",
            }}
          >
            <Grid container>
              <Grid
                size={12}
                sx={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: "600",
                  letterSpacing: "-0.20800000429153442px",
                  color: "#000000",
                }}
              >
                Usage
              </Grid>
              <Grid
                size={8}
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#6B7280",
                  marginTop: "8px",
                }}
              >
                Input tokens
              </Grid>
              <Grid
                size={4}
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#111827",
                  marginTop: "8px",
                }}
              >
                {getUsageQuery?.data?.input_tokens}
              </Grid>
              <Grid
                size={8}
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#6B7280",
                  marginTop: "8px",
                }}
              >
                Output tokens
              </Grid>
              <Grid
                size={4}
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#111827",
                  marginTop: "8px",
                }}
              >
                {getUsageQuery?.data?.output_tokens}
              </Grid>
              <Grid
                size={8}
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#6B7280",
                  marginTop: "8px",
                }}
              >
                Total Tokens
              </Grid>
              <Grid
                size={4}
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#111827",
                  marginTop: "8px",
                }}
              >
                {getUsageQuery?.data?.total_tokens}
              </Grid>
              <Grid
                size={8}
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#6B7280",
                  marginTop: "8px",
                }}
              >
                Total Assistants
              </Grid>
              <Grid
                size={4}
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#111827",
                  marginTop: "8px",
                }}
              >
                {getUsageQuery?.data?.total_chatbots}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/* <Grid size={4}>
          <Paper
            sx={{
              border: "1px solid #E5E7EB",
              borderRadius: "8px",
              padding: "17px",
            }}
          >
            <Grid container>
              <Grid
                size={12}
                sx={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: "600",
                  letterSpacing: "-0.20800000429153442px",
                  color: "#000000",
                }}
              >
                Usage
              </Grid>
              <Grid
                size={8}
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#6B7280",
                  marginTop: "8px",
                }}
              >
                Characters Used
              </Grid>
              <Grid
                size={4}
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#111827",
                  marginTop: "8px",
                }}
              >
                104,692
              </Grid>
              <Grid
                size={8}
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#6B7280",
                  marginTop: "8px",
                }}
              >
                Assistants
              </Grid>
              <Grid
                size={4}
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#111827",
                  marginTop: "8px",
                }}
              >
                10
              </Grid>
              <Grid
                size={8}
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#6B7280",
                  marginTop: "8px",
                }}
              >
                Team Members
              </Grid>
              <Grid
                size={4}
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#111827",
                  marginTop: "8px",
                }}
              >
                4
              </Grid>
            </Grid>
          </Paper>
        </Grid> */}
        {/* <Grid size={4}>
          <Paper
            sx={{
              border: "1px solid #E5E7EB",
              borderRadius: "8px",
              padding: "17px",
            }}
          >
            <Grid container>
              <Grid
                size={12}
                sx={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: "600",
                  letterSpacing: "-0.20800000429153442px",
                  color: "#000000",
                }}
              >
                Usage
              </Grid>
              <Grid
                size={8}
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#6B7280",
                  marginTop: "8px",
                }}
              >
                Characters Used
              </Grid>
              <Grid
                size={4}
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#111827",
                  marginTop: "8px",
                }}
              >
                104,692
              </Grid>
              <Grid
                size={8}
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#6B7280",
                  marginTop: "8px",
                }}
              >
                Assistants
              </Grid>
              <Grid
                size={4}
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#111827",
                  marginTop: "8px",
                }}
              >
                10
              </Grid>
              <Grid
                size={8}
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#6B7280",
                  marginTop: "8px",
                }}
              >
                Team Members
              </Grid>
              <Grid
                size={4}
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#111827",
                  marginTop: "8px",
                }}
              >
                4
              </Grid>
            </Grid>
          </Paper>
        </Grid> */}
      </Grid>
      {open && (
        <AssistantModal
          botData={botData}
          open={open}
          setOpen={setOpen}
          setPagination={setPagination}
          pagination={pagination}
        />
      )}
      {create && <CreateDialog open={create} setOpen={setCreate} />}
    </>
  );
};
