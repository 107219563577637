import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import UIRoutes from "./routes";
import { persistStore } from "redux-persist";
import { store } from "./redux/store";
import "./index.css";
import { StyledEngineProvider } from "@mui/material/styles";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

const theme = createTheme({
  typography: { 
    fontFamily: 'Inter',
    // fontSize: 14,
    // body1: { fontSize: '0.875rem', color:'#000', fontWeight:600 }, 
    // subtitle1: { fontSize: '1rem', color:'#61646B'}, 
    // caption: { fontSize: '0.75rem', color:'#61646B'}
  }
});
const persistor = persistStore(store);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}> <CssBaseline />
            <UIRoutes />
            </ThemeProvider>
          </StyledEngineProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
