import "./style.css";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
export const StyledDataGrid = styled((props: any) => {
  const { children, ...rest } = props;
  return <DataGrid {...rest}>{children}</DataGrid>;
})`
  ${(props) => props.tableHeight && `height:${props.tableHeight}px !important`}
`;

export const Table = (props: any) => {
  const {
    rows,
    rowCount,
    columns,
    checkboxSelection,
    isPagination,
    pagination,
    onPaginationModelChange,
    tableHeight,
    // autoHeight,
    columnVisibilityObject,
    onRowSelectionModelChange,
    className,
    scroll,
    sortModel,
    onSortModelChange,
    onRowClick,
  } = props;
  // const SortedDescendingIcon = () => {
  //   return <ArrowDropDownIcon className="icon" />;
  // };
  // const SortedAscendingIcon = () => {
  //   return <ArrowDropUpIcon className="icon" />;
  // };
  return (
    <StyledDataGrid
      tableHeight={tableHeight}
      rows={rows}
      // autoHeight={autoHeight}
      columns={columns}
      className={className ? `${className} tb-0` : "tb-0"}
      initialState={{
        pagination: { paginationModel: pagination },
        columns: {
          columnVisibilityModel: columnVisibilityObject,
        },
      }}
      rowHeight={52}
      getRowSpacing={() => ({ top: 4 })}
      getRowClassName={(params: any) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
      }
      pagination={isPagination}
      // pageSizeOptions={[10,20]}
      paginationModel={pagination}
      paginationMode="server"
      onPaginationModelChange={onPaginationModelChange}
      checkboxSelection={checkboxSelection}
      disableColumnFilter
      disableColumnSelector
      onRowSelectionModelChange={onRowSelectionModelChange}
      disableColumnMenu
      rowCount={rowCount}
      showColumnRightBorder
      sortingMode="server"
      sortModel={sortModel}
      onSortModelChange={onSortModelChange}
      disableRowSelectionOnClick={true}
      onRowClick={onRowClick}
      pageSize={20}
      slots={
        {
          //   columnSortedDescendingIcon: SortedDescendingIcon,
          //   columnSortedAscendingIcon: SortedAscendingIcon,
          //   columnUnsortedIcon: SortedDescendingIcon,
          // noRowsOverlay: () => (
          //   <Stack height="100%" alignItems="center" justifyContent="center">
          //     <img src={file} alt="file" />
          //     <p className="clickText">No Data Available</p>
          //   </Stack>
          // ),
        }
      }
    />
  );
};
