import { Route, Routes } from "react-router-dom";

import { useSelector } from "react-redux";

import { SignIn } from "../components/pages/auth";
import { Page } from "./config";
import { VerifyOtp } from "../components/pages/auth/verifyOtp";
import { ProtectedRoute } from "./protectedRoute";
import { getIsLoggedIn } from "../redux/features/auth/authSlice";
import { Header } from "../components/layout/header";
import { KnowledgeBases } from "../components/pages/knowledgebases";
import { ChatInterface } from "../components/pages/bots/chatInterface";
import { Home } from "../components/pages/dashboard/home";
import { CreateAssistant } from "../components/pages/assistant";
import { Sources } from "../components/pages/assistant/sources";
import { Connect } from "../components/pages/assistant/connect";
import { Integration } from "../components/pages/assistant/integration";

const UIRoutes = () => {
  const renderPaths = (paths: any, Element: any) => {
    return paths.map((path: any) => {
      return <Route key={path} path={path} element={Element} />;
    });
  };
  const isLoggedIn = useSelector(getIsLoggedIn);

  return (
    <Routes>
      {!isLoggedIn && <Route index element={<SignIn />} />}
      <Route path={Page.OTP} element={<VerifyOtp />}></Route>

      <Route element={<ProtectedRoute isLoggedIn={isLoggedIn} />}>
        <Route path="/" element={<Header />}>
          <Route path={Page.SETTINGS} element={<CreateAssistant />} />
          <Route path={Page.SOURCES} element={<Sources />} />
          <Route path={Page.INTEGRATION} element={<Integration />} />
          <Route path={Page.CHAT} element={<ChatInterface />} />
          <Route path="/" element={<Home />} />
          <Route path={Page.CONNECT} element={<Connect />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default UIRoutes;
