import { Typography, Box, Link } from "@mui/material";
import Grid from "@mui/material/Grid2";
import "./style.css";
import Buttons from "../../common/button";
import { useState, ChangeEvent, useRef } from "react";
import { FormFieldProps } from "./interface";
import { Input } from "../../common/input";

type BotSettingsFormProps = {};

const StarterQuestion: React.FC<BotSettingsFormProps> = () => {
  const [allFieldTypes, setAllFieldTypes] = useState<FormFieldProps[]>([
    { index: 0, fieldtype: "text" },
  ]);
  const [questions, setQuestions] = useState<string[]>(['']);
  const [answers, setAnswers] = useState<string[]>(['']);
  const addButtonRef = useRef<HTMLButtonElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);


  const handleSave = (index: number) => {
    console.log('Question:', questions[index]);
    console.log('Answer:', answers[index]);
  };

  const handleCancel = (id: number) => {
    setAllFieldTypes((prevItems) =>
      prevItems.filter((_ele, _eleIndex) => _eleIndex !== id)
    );
  };

  const handleQuestionChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
    const newQuestions = [...questions];
    newQuestions[index] = event.target.value;
    setQuestions(newQuestions);
  };

  const handleAnswerChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
    const newAnswers = [...answers];
    newAnswers[index] = event.target.value;
    setAnswers(newAnswers);
  };

  const addNewStarterQuestion = () => {
    setAllFieldTypes([...allFieldTypes, { index: allFieldTypes.length, fieldtype: "text" }]);
    setQuestions([...questions, '']);
    setAnswers([...answers, '']);
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
    if (addButtonRef.current) {
      addButtonRef.current.focus();
      // addButtonRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    < div style={{width:'95%'}}>
      <Box p={2} className="starterForm" ref={containerRef}>
        <Grid container>
          <Grid size={9}>
            <Typography pr={2}>
              These will be shown upfront to your user as nudges. You can style
              the answers using markdown.
            </Typography>
          </Grid>
          <Grid size={2}>
            <Buttons type="secondary" text="Learn more" />
          </Grid>
        </Grid>
        <Grid container>
          <Grid size={12}>
            <Typography variant="caption">
              Note: Now you can use @ symbol to add variables.
              <Link
                href="#"
                className="primaryLink"
                sx={{ marginLeft: "0.5rem" }}
              >
                Learn more
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <form noValidate autoComplete="off">
            {allFieldTypes.length > 0 && (
              <Grid container marginBottom={"0.5rem"}>
                <Typography variant="body1">Add starter question</Typography>
                {allFieldTypes.map((field, index) => (
                  <div key={index} className="formFieldsContainer">
                    <Grid size={12} sx={{marginBottom:'0.5rem'}}>
                      <label>Question *</label>
                      <Input
                        name={`question${index}`}
                        value={questions[index]}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => handleQuestionChange(index, event)}
                      />
                    </Grid>
                    <Grid size={12}>
                      <label>Answer *</label>
                      <Input
                        name={`answer${index}`}
                        value={answers[index]}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => handleAnswerChange(index, event)}
                      />
                    </Grid>
                    <Grid size={12} className="btnFlexEnd">
                      <Buttons
                        type="secondary"
                        text="Cancel"
                        sx={{ margin: "0.5rem 0", width: "100px" }}
                        onClick={() => handleCancel(index)}
                      />
                      <Buttons
                        type="primary"
                        text="Save"
                        sx={{ margin: "0.5rem 0", width: "100px" }}
                        onClick={() => handleSave(index)}
                      />
                    </Grid>
                  </div>
                ))}
              </Grid>
            )}
            <Grid size={5}>
              <Buttons
                sx={{ marginTop: "1rem", width: "200px" }}
                type="secondary"
                start="addIcon"
                text="Add new starter question"
                ref={addButtonRef}
                onClick={addNewStarterQuestion}
                className="stickyButton"
              />
            </Grid>
          </form>
        </Grid>
      </Box>
    </div>
  );
};

export default StarterQuestion;